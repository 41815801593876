import logo from './images/Trans-CircleImagFoodVariety.png';
import './App.css';
import { Components, useState } from 'react';
import LoginSignup from './Components/LoginSignup/LoginSignup';
import axios from 'axios';
import getData from "./Components/GetData"
import PrintData from './Components/PrintData';
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import phone from './images/TransPhonePaint1.png'

function  App(){
  const hStyle = { color: 'red' };
  const hYellow = { color: 'yellow' };
  const hGreen = { color: 'green' };
    return (
      <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         DEVELOPMENT IN PROGRESS FOR MAKKAH CATERING.
         <br></br>
        WE CREATE WEBSITES TO SUITE ANY TYPE OF BUSINESS.
        </p>
        
        {/* <img src={phone} alt="phone" />  */}
        <span alt="phone" style={hStyle}>  <img src={phone} alt="phone" /></span>
        
        <a class="single" href="tel: 630-475-1234" style={hYellow}> Call for catering order </a>
        <a class="single" href="tel: 312-714-9744" style={hGreen}> Call for website development </a>
        <p>
          <span style={hStyle}>Address:</span>
          <p>17W612 14th St. Oakbrook Terrace, IL 60181</p>
        </p>
      
      
       
      </header>
    </div>

  );

}

export default App;